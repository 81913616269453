<template>
  <el-form :model="additionalcontractForm" :rules="rules" ref="form" label-width="100px" class="additionalcontractForm" size="small">
    <el-form-item label="所属合同" prop="contract">
      <el-select v-model="additionalcontractForm.contract" placeholder="请选择所属合同">
        <el-option v-for="item in contracts_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="追加时间" prop="additional_contract_date">
      <el-date-picker
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        v-model="additionalcontractForm.additional_contract_date"
        :picker-options="pickerOptions"
        style="width: 100%;"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="追加金额" prop="additional_contract_amount">
      <el-input v-model.number="additionalcontractForm.additional_contract_amount"></el-input>
    </el-form-item>

    <el-form-item label="追加材料" prop="additional_contract_material">
      <el-input type="textarea" v-model="additionalcontractForm.additional_contract_material"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    additionalcontractForm: Object
  },
  data() {
    return {
      isValidationOk: false,
      contracts_options: [],
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        contract: [{ required: true, message: '请选择所属合同', trigger: 'blur' }],
        additional_contract_date: [{ required: true, message: '请选择合同外追加时间', trigger: 'blur' }],
        additional_contract_amount: [
          { required: true, message: '请输入合同外追加金额', trigger: 'blur' },
          { type: 'number', message: '合同外追加金额必须为数字' }
        ]
      }
    }
  },
  methods: {
    getContracts() {
      this.$axios.get('/getContracts/').then(res => {
        console.log('get contracts list:', res.data)
        res.data.data.forEach(element => {
          let contract = {}
          contract.label = String(element.contract_name) + '(' + String(element.contract_id) + ')'
          contract.value = element.id
          this.contracts_options.push(contract)
        })
        console.log('contracts_options', this.contracts_options)
      })
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    }
  },
  created() {
    this.getContracts()
  }
}
</script>

<style lang="scss" scoped></style>
